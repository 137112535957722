.scroll-to-top {
    position: fixed;
    cursor: pointer;
    border-radius: 50%;
    color: white;
}

@media (min-width: 600px) {
    .scroll-to-top {
        right: 100px;
        bottom: 50px;
    }
}
@media (max-width: 599.9px) {
    .scroll-to-top {
        display: none;
    }
}